<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">EVENTS</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <el-table
      :data="dataEvents"
      style="width: 100%">
      <el-table-column
        label="Name">
            <template #default="scope">
              <el-input size="small"
                style="text-align:center"
                v-model="scope.row.name"></el-input>
            </template>
      </el-table-column>
      <el-table-column
        label="Desc">
            <template #default="scope">
              <el-input size="small"
                style="text-align:center"
                v-model="scope.row.description"></el-input>
            </template>
      </el-table-column>
      <el-table-column
        label="Price">
            <template #default="scope">
              <el-input-number size="small"
                style="text-align:center"
                :controls="false"
                v-model="scope.row.cost"></el-input-number>
            </template>
      </el-table-column>
      <el-table-column
        label="is Default">
            <template #default="scope">
              <el-switch size="small" v-model="scope.row.isDefault"></el-switch>
            </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">

            <div v-if="scope.$index >= events.length">
                <el-button
                 @click.native.prevent="addEvent(scope.row)" type="text" size="small">
                    Create
                </el-button>
            </div>
            <div class="flex gaps" v-else>
                <el-button @click.native.prevent="saveRow(scope.row)" type="text" size="small">
                    Save
                </el-button>
                <el-button @click.native.prevent="deleteRow(scope.row, scope.$index)" type="text" size="small">
                    Delete
                </el-button>
            </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex justify-flex-end m-10">
        <el-button @click="addRow()" size="small">
            Add Event
        </el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Event',

  data () {
    return {
      dataEvents: []
    }
  },

  computed: {
    ...mapState({
      events: state => state.event.events
    })
  },

  methods: {
    deleteRow (row, index) {
      this.$store.dispatch('removeEvent', row._id)
        .then(() => {
          this.dataEvents.splice(index, 1)
        })
    },
    saveRow (row) {
      this.$store.dispatch('editEvent', {
        id: row.uid,
        content: row
      })
    },
    addEvent (payload) {
      this.$store.dispatch('addEvent', payload)
    },
    addRow () {
      let newRow = {
        name: '',
        description: '',
        cost: 0,
        isDefault: false
      }
      this.dataEvents = [...this.dataEvents, newRow]
    }
  },
  mounted () {
    this.dataEvents = this.events.map((x) => Object.assign({}, x))
  }
}
</script>

<style lang="scss">
</style>
